import React from 'react';
import heroThumbOne from '../../assets/images/hero-thumb-1.png';
import heroThumbTwo from '../../assets/images/hero-thumb-2.png';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';

function HeroHomeOne({className}) {
    return (
        <>
            <section className={`appie-hero-area ${className || ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="appie-hero-content text-center">
                                <span>Welcome To ATMS - Agan Integration</span>
                                <h1 className="appie-title">
                                    Manage your ATMS without any hassle.
                                </h1>
                                <p>
                                    ATMS - Agan Integration make your life easy and automated.
                                </p>
                                <ul>
                                    <li>
                                        <a href="#">
                                            Download For Shopify
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="#">
                                            Download For Wordpress
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt=""/>
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt=""/>
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt=""/>
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;
