import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import FaqHomeOne from './FaqHomeOne';
import ProjectHomeOne from "./ProjectHomeOne";
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import PricingHomeOne from './PricingHomeOne';
import ServicesHomeOne from './ServicesHomeOne';
import TestimonialHomeOne from './TestimonialHomeOne';
import TrafficHomeOne from './TrafficHomeOne';
import Forms from "../Contact/Forms";
import {Helmet} from "react-helmet";

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Helmet>
                <title>ATMS - Agan Integration</title>
                <meta name="description" content="ATMS - Agan Integration"/>
            </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle}/>
            <HomeOneHeader action={drawerAction.toggle}/>
            <HeroHomeOne/>
            <ServicesHomeOne/>
            <TrafficHomeOne/>
            <TestimonialHomeOne/>
            <ProjectHomeOne/>
            <FaqHomeOne/>
            <Forms/>
            <BackToTop/>
        </>
    );
}

export default HomeOne;
