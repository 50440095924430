import React from 'react';
import thumb from '../../assets/images/s1.jpg';

function TrafficHomeOne({className}) {
    return (
        <section className={`appie-traffic-area pt-140 pb-180 ${className || ''}`} id='features'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="appie-traffic-title">
                            <span>Features</span>
                            <h3 className="title">ATMS - Agan Integration Features</h3>
                            <p>
                                ATMS - Agan Integration app add next level features. Which are very useful and easy to
                                use.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check"/>
                                    </div>
                                    <h5 className="title">Automatic Order Sync</h5>
                                    <p>Sync your all new order with Agan ATMS automatically.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-2 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check"/>
                                    </div>
                                    <h5 className="title">Manual Order Sync</h5>
                                    <p>Store owners manually sync order with Agan ATMS automatically.</p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="appie-traffic-service item-3">
                                    <div className="icon">
                                        <i className="fal fa-check"/>
                                    </div>
                                    <h5 className="title">Advanced Log System</h5>
                                    <p>
                                        Our app has an advanced logging system. From the log you can see the XML that we
                                        send to Agan ATMS also you can see the response from the ATMS also the DMS ID
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="traffic-thumb ">
                <img
                    className="wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                    src={thumb}
                    alt=""
                />
            </div>
        </section>
    );
}

export default TrafficHomeOne;
