import logo from '../../assets/images/logo-2.png';
import { HashLink } from 'react-router-hash-link';
function Drawer({drawer, action}) {
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            />
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className={`offcanvas_menu_wrapper ${
                                    drawer ? 'active' : ''
                                }`}
                            >
                                <div className="canvas_close">
                                    <a href="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="" height={`80px`}/>
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li>
                                            <a href="/">
                                                Home
                                            </a>
                                        </li>
                                        <li>
                                            <HashLink smooth to='/#process' >How It Works</HashLink>
                                        </li>
                                        <li>
                                            <HashLink smooth to="/#features">Features</HashLink>
                                        </li>
                                        <li>
                                            <HashLink smooth to="/#testimonials">Testimonials</HashLink>
                                        </li>
                                        <li>
                                            <HashLink smooth to="/#pricing">Pricing</HashLink>
                                        </li>
                                        <li>
                                            <HashLink smooth to="/#faq">Faq</HashLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-envelope"></i> we@else.co.il
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-phone"></i> +972-72-2222-154
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
