import React, {useRef} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import user from '../../assets/images/testimonial-user.png';

function TestimonialHomeOne({className}) {
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <section
            className={`appie-testimonial-area pt-100 pb-160 ${className || ''}`}
            id="testimonials"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="appie-testimonial-slider" style={{position: 'relative'}}>
                            <span
                                className="prev slick-arrow"
                                style={{display: 'block'}}
                                onClick={sliderNext}
                                role="button"
                                tabIndex="0"
                            >
                                <i className="fal fa-arrow-left"/>
                            </span>
                            <Slider ref={sliderRef} dots arrows={false}>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <h5 className="title">Jaira Emanuel</h5>
                                    </div>
                                    <div className="text">
                                        <p>
                                            I'm using Agan from last year and its reduce my work. I am very happy with
                                            this plugin. I suggest this plugin for everyone who use Agan, wordpress and
                                            shopify.
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <h5 className="title">Levey Goldberg</h5>
                                    </div>
                                    <div className="text">
                                        <p>
                                            ATMS - Agan Integration App helps me to manage my store easily. Now i don't
                                            need to manually add data to Agan ATMS.
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Slider>
                            <span
                                onClick={sliderPrev}
                                role="button"
                                tabIndex="-1"
                                className="next slick-arrow"
                                style={{display: 'block'}}
                            >
                                <i className="fal fa-arrow-right"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeOne;
