import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import Drawer from '../Mobile/Drawer';
import HeaderNews from '../News/HeaderNews';
import HeroNews from '../News/HeroNews';
import {Helmet} from 'react-helmet'

function PrivacyPolicy() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Helmet>
                <title>ATMS - Agan Integration - Privacy Policy</title>
                <meta name="description" content="ATMS - Agan Integration - Privacy Policy"/>
            </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle}/>
            <HeroNews
                title="Privacy Policy"
                breadcrumb={[
                    {link: '/', title: 'Home'},
                    {link: '/privacy-policy', title: 'Privacy Policy'},
                ]}
            />
            <div className="container">
                <div className='pt-100 pb-100'>
                    <p>
                        To provide the Service, ATMS - Agan Integration collects, maintains, and processes information
                        about the
                        Client and his/her Customers. It also provides its customers with useful and comparable data
                        processing
                        based on its Clients and their Customers and Stores. <br/> <br/>

                        We understand that you value your privacy, and we want to help make your experience with our
                        Services satisfying and safe. So we provide this Privacy Policy to inform you of our policies
                        and procedures regarding the collection, use, and disclosure of information we receive from
                        users of
                        our website and services. Subscribing or using the Service means that you have read, understood,
                        and
                        agreed to the entirety of this Privacy Policy. <br/> <br/>

                        As we have no direct relationship with your customers and Visitors and any other third parties
                        you added to the ATMS - Agan Integration, you are solely responsible for informing them about
                        the information collected and making sure you have the appropriate permission for us to collect
                        and process
                        information about those individuals. <br/> <br/>

                        This Privacy Policy may be updated from time to time. For example, we may update this Privacy
                        Policy
                        to reflect changes to our information collection and use practices. So you should review this
                        page
                        periodically for the latest information on our privacy practices.
                    </p>
                    <br/>
                    <h2>
                        1. Information Collected
                    </h2>
                    <br/>
                    <h4 className='ml-5'>
                        1.1 Information You Provide to Us
                    </h4>
                    <br/>
                    <p className='ml-5'>
                        We collect and maintain certain information you provide directly to us when subscribing into
                        ATMS - Agan Integration or installing the application to your Store or using the Service. The
                        information we
                        collect may include your personal and/or company name, telephone number, email address, postal
                        address, your Customers’s information, and other information you choose to provide. <br/> <br/>

                        Also, any email or message you send us will be saved. This data will be used to communicate with
                        you and provide service and information and other interactions with you. <br/> <br/>

                        You may also create some texts and contents within the Service to provide a better experience
                        for your customers. All your content produced within the Service will be kept in ATMS - Agan
                        Integration and
                        will be used to provide the Service to you.
                    </p>
                    <br/>
                    <h4 className='ml-5'>
                        1.2 Information Collected Automatically
                    </h4>
                    <br/>
                    <p className='ml-5'>
                        When you subscribe to the Service or install ATMS - Agan Integration on your Store, Service is
                        automatically able to access certain types of information that we may collect, save, and process
                        continuously:
                    </p>
                    <br/>
                    <p className="ml-5">
                        1. Information of your Store which is accessible from your Shopify account:
                    </p>
                    <br/>
                    <ul className="ml-60" style={{listStyle: 'disc inside'}}>
                        <li>
                            <strong>Shop resource:</strong> the Store’s general settings and information, including
                            name, domain, currency, language, location, and contact email address
                        </li>
                        <li>
                            <strong>Products:</strong> the Store’s product catalog, including details on each product
                            variant, titles, descriptions, prices, images, and collections.
                        </li>
                        <li>
                            <strong>Orders:</strong> the store’s orders including details of the customer, their cart,
                            and any transactions.
                        </li>
                        <li>
                            <strong>Storefront:</strong> the store’s themes, including all files, pages, texts, assets,
                            and script tags.
                        </li>
                    </ul>
                    <p className="ml-5">
                        2. Information about you or your Visitors who register for a back in stock notification:
                    </p>
                    <br/>
                    <ul className="ml-60" style={{listStyle: 'disc inside'}}>
                        <li>
                            <strong>Device Information:</strong> We may collect certain information about the devices
                            you use or your Visitor uses with the Service, including such data as unique device
                            identifiers, device brand, and model, device name, push notification identifiers, Internet
                            Protocol (IP) address, operating system, time zone, language, and browser types.
                        </li>
                        <li>
                            <strong>Usage Information:</strong> We may collect information about how people use the
                            Service or Store. This data may include Service usage times, visited pages, clicked links,
                            ordered products, page view times, and linked IPs. We may keep track of how you interact
                            with links across our Services by redirecting links or through other means.
                        </li>
                        <li>
                            <strong>Location Information:</strong> We may also automatically collect your physical
                            location if you choose to enable your computer or mobile device to send us location
                            information.
                        </li>
                        <li>
                            <strong>Cookies and Other Tracking Information:</strong> For commercial purposes and
                            personalized services, the permission of setting cookies is taken from you and these cookies
                            will be placed on your computer which, among other uses, allow you to sign in to your
                            Account in a browser and maintain an identified browsing session with ATMS - Agan
                            Integration
                        </li>
                    </ul>
                    <br/>
                    <h2>
                        2. Use of Information
                    </h2>
                    <br/>
                    <p className='ml-5'>
                        1. We use the collected information in order to provide the Service, improving its features, and
                        developing new features such as the following purposes:
                    </p>
                    <br/>
                    <ul className="ml-60" style={{listStyle: 'disc inside'}}>
                        <li>
                            To process requests or transactions you initiate by accessing or using the Service.
                        </li>
                        <li>
                            To monitor and analyze usage and trends in order to improve the Service, or to improve your
                            experience with the Service, for example by providing personalized content, features, or
                            advertisements for you or your Visitors.
                        </li>
                        <li>
                            To verify your identity or your Visitors’ identity, in order to protect your privacy and
                            keeping data protected;
                        </li>
                        <li>
                            To contact or notify you or your Visitors of security alerts, important updates to our
                            Privacy Policy, or support your use of Service and respond to your comments, questions, and
                            requests;
                        </li>
                        <li>
                            To link or combine with information we get from others to help understand your needs or
                            provide you with better services;
                        </li>
                        <li>
                            To detect, investigate, and/or prevent fraudulent, unauthorized, or illegal activity.
                        </li>
                    </ul>
                    <br/>
                    <p className='ml-5'>
                        2. We may also analyze the collected information, and provide and publish some charts and
                        statistics with commercial usages for public use, or publish their results in a targeted and
                        usable way for various institutions or businesses, and present them to their owners. We may also
                        use this information to develop other services. All of these usages are with fully protecting
                        your and your Vistors’ privacy Without any Personal Information being made available or
                        traceable to others. Therefore, except in cases of legal and judicial requirements, we never
                        provide or sell information that can be identified as belongs to who (you or one of your users)
                        to others.
                    </p>
                    <br/>
                    <h2>
                        3. Protecting, Securing, and Sharing of Information
                    </h2>
                    <br/>
                    <p className='ml-5'>
                        Data security is of great importance to us. All Personal Data is stored securely in accordance
                        with the principles of the Data Protection Act 1998 and the European Union’s General Data
                        Protection Regulation. <br/> <br/>

                        To protect your Data we have put in place suitable physical, electronic, and managerial
                        procedures to safeguard and secure Data collected via ATMS - Agan Integration. Therefore If
                        password access
                        is required for certain parts of the Service, you are responsible for keeping this password
                        confidential. We endeavor to do our best to protect your Personal Data. However, the
                        transmission of information over the internet is not always perfectly secure and is done at your
                        own risk, and we will not be liable in the case of any unauthorized access to the data which was
                        not directly caused by data leakage or disclosure from inside of our storages. <br/> <br/>

                        Personal Information collected through the Service will never be shared with others, except in
                        the following cases:
                    </p>
                    <br/>
                    <h4 className='ml-5'>
                        1. With your consent
                    </h4>
                    <br/>
                    <p className={`ml-5`}>
                        When you give us consent to do so, including if we notify you that the information you provide
                        will be shared in a particular manner and you provide such information.
                    </p>
                    <br/>
                    <h4 className='ml-5'>
                        2. With Third Party Services
                    </h4>
                    <br/>
                    <p className={`ml-5`}>
                        We share necessary Data with Third Party Services, consultants, or others who are working with
                        us and need access to such information to carry out work on our behalf, and in this way, we can
                        provide you the Service. Any Data used by such parties is used only to the extent required by
                        them to perform the services that we request. Any use for other purposes is strictly prohibited.
                        Furthermore, any Data that is processed by third parties will be processed within the terms of
                        this privacy policy and in accordance with the Data Protection Act 1998 and GDPR.
                    </p>
                    <br/>
                    <h4 className='ml-5'>
                        3. In response to legal process or a request for information
                    </h4>
                    <br/>
                    <p className={`ml-5`}>
                        If we believe disclosure is in accordance with any applicable law, rule, or regulation, we may
                        share your Personal Information with appropriate individuals in such cases:
                    </p>
                    <br/>
                    <ul className="ml-60" style={{listStyle: 'disc inside'}}>
                        <li>
                            Applying any applicable law, ruleو regulation, or court judgment
                        </li>
                        <li>
                            Applying Terms of Service that can be applied for inspections or detecting violations
                        </li>
                        <li>
                            In order to investigate or remedy potential violations of our user agreements or policies
                        </li>
                        <li>
                            to protect the rights, property, and safety of Hengam, our users, or others, which is
                            necessary or allowed due to regulations.
                        </li>
                        <li>
                            To link or combine with information we get from others to help understand your needs or
                            provide you with better services;
                        </li>
                        <li>
                            To detect, investigate, and/or prevent fraudulent, unauthorized, or illegal activity.
                        </li>
                    </ul>
                    <br/>
                    <h4 className='ml-5'>
                        4. Inside the Company
                    </h4>
                    <br/>
                    <p className={`ml-5`}>
                        Employees of Else whose job responsibilities require access to Personal Information are
                        provided with such access and will use it solely to provide service and support to Clients, and
                        these employees are subject to strict rules on the protection and confidentiality of
                        information.
                    </p>
                    <br/>
                    <h4 className='ml-5'>
                        5. Sharing Non-Personal Information
                    </h4>
                    <br/>
                    <p className={`ml-5`}>
                        We may publish Non-Personal Information in different formats, or share them with our partners,
                        such as publishers, advertisers, business owners, and researchers. For example, we may share
                        some statistics related to the price of goods, the frequency and stock of goods in different
                        places, the number of visits to different goods or stores, or provide statistics from them to
                        others.
                    </p>
                    <br/>
                    <h2>
                        4. Storing and Deleting of Information
                    </h2>
                    <br/>
                    <p className={`ml-5`}>
                        All information collected in ATMS - Agan Integration is always securely stored and will be kept
                        as long as it
                        is required to provide the Service and improve it or other uses listed in the Terms. <br/> <br/>

                        We provide our Clients to respond directly to data subject requests to access, transfer, rectify
                        or erase their personal data, or to restrict or object to the processing of their personal data.<br/>
                        <br/>

                        If you contact us at our official email addresses to request something about your Personal
                        Information, we may require you to submit additional information necessary to verify your
                        identity and status as an EU data subject and we will respond to your request directly within 30
                        days after identity verification.<br/><br/>

                        If we are processing your personal data based upon the lawful ground of your consent, you have
                        the right to withdraw your consent for such processing at anytime without affecting the
                        lawfulness of processing based on consent before it is withdrawn. To withdraw consent, email us
                        at our support email address.<br/><br/>

                        If you stop using the Service, unless you request deletion of your Personal Information, we may
                        hold your Personal Information forever after a Client stops using the Service.
                    </p>
                    <br/>
                    <h2>
                        5. Changes of business ownership and control
                    </h2>
                    <br/>
                    <p className={`ml-5`}>
                        In the case of sale and/or the transfer of control of all or part of ATMS - Agan Integration,
                        data provided
                        by Clients will, where it is relevant to any part of our business so transferred, be transferred
                        along with that part and the new owner or newly controlling party will, under the terms of this
                        Privacy Policy, be permitted to use the Data for the purposes for which it was originally
                        supplied to us, and the confidentiality of Personal Information will be preserved.<br/><br/>

                        If such data transfer requires implementing new and completely different policies than before,
                        this will be promoted to you as described in the part of changes of this Privacy Policy, and
                        then the ownership or control will be transferred to the new one.<br/><br/>

                        We may also disclose Data to a prospective purchaser of our business or any part of it. In the
                        above instances, we will take steps with the aim of ensuring your privacy is protected due to
                        this Privacy Policy.
                    </p>

                    <br/>
                    <h2>
                        6. Changes to this privacy policy
                    </h2>
                    <br/>
                    <p className={`ml-5`}>
                        We reserve the right to change this Privacy Policy as we may deem necessary from time to time or
                        as may be required by law. We may update this Privacy Policy from time to time in order to
                        reflect, for example, changes to our practices or for other operational, legal, or regulatory
                        reasons.<br/><br/>

                        Any changes will be immediately posted on the Website and you are deemed to have accepted the
                        terms of the privacy policy on your first use of the Website following the
                        alterations.<br/><br/>

                        If you decide not to use the Service in accordance with the policies of this Privacy Policy or
                        its updated versions, you will need to close your account.
                    </p>

                    <br/>
                    <h2>
                        7. DPA and GDPR Subject Rights
                    </h2>
                    <br/>
                    <p className={`ml-5`}>
                        1. If you reside in the European Economic Area or Switzerland (collectively the “EU”), we note
                        that
                        we are processing your information and we rely on the following lawful grounds under the General
                        Data Protection Regulation (“GDPR”) to process (collect, store, and use) your Personal
                        Information:
                    </p>
                    <br/>
                    <ul className="ml-60" style={{listStyle: 'disc inside'}}>
                        <li>
                            When it is necessary for the performance of a contract;
                        </li>
                        <li>
                            our or a third party’s legitimate business interest; or
                        </li>
                        <li>
                            your consent.
                        </li>
                    </ul>

                    <br/>
                    <p className={`ml-5`}>
                        2. Always you will be given options to restrict our use of Data or to access them That may
                        include the following:
                    </p>
                    <br/>
                    <ul className="ml-60" style={{listStyle: 'disc inside'}}>
                        <li>
                            You have the right to ask for a copy of any of your store’s account data held by ATMS - Agan
                            Integration
                        </li>
                        <li>
                            You have the right to withdraw the consent necessary to collect and use your data at any
                            time.
                        </li>
                        <li>
                            You have the right for your data to be deleted from the servers and databases of ATMS - Agan
                            Integration.
                        </li>
                    </ul>
                    <br/>
                    <p className={`ml-5`}>
                        3. You can control your data due to the policies described in section 4.
                    </p>
                    <br/>
                    <p className={`ml-5`}>
                        4. You may decline to submit information through the Service or restrict our access to data, or
                        it may be restricted at your browser’s settings, and so we may not be able to provide all or a
                        portion of the Service to you.
                    </p>
                    <br/>
                    <p className={`ml-5`}>
                        5. Security of your data is under the policies of section 3.
                    </p>
                    <br/>
                    <p className={`ml-5`}>
                        6. ATMS - Agan Integration may place and access certain Cookies on your computer. Before that,
                        you will be
                        presented with a message bar requesting your consent to set those Cookies, and you should get
                        this consent from your Visitors. Cookies are used to improve your experience of using the
                        Service and to improve our range of products and services. You can choose to enable or disable
                        Cookies in your internet browser and you can choose to delete Cookies at any time; however
                        certain features of the Service may not function fully or as intended.
                    </p>
                    <br/>
                </div>
            </div>
            <BackToTop/>
        </>
    );
}

export default PrivacyPolicy;
