import React from 'react';
import OrderIcon from '../../assets/images/icon/order.png';
import OrderListIcon from '../../assets/images/icon/orderlist.png';
import XmlIcon from '../../assets/images/icon/xml.png';
import ApiIcon from '../../assets/images/icon/api.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="process">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                                How It Work
                            </h3>
                            <p>ATMS - Agan Integration working process</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={OrderIcon} alt="" />
                                <span>1</span>
                            </div>
                            <h4 className="appie-title">
                                Customer New Order
                            </h4>
                            <p>
                                When your store get a new order our app automatically detect it.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={OrderListIcon} alt="" />
                                <span>2</span>
                            </div>
                            <h4 className="appie-title">Extract Order Details</h4>
                            <p>
                                Our app automatically extract all the data from the new order.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={XmlIcon} alt="" />
                                <span>3</span>
                            </div>
                            <h4 className="appie-title">Make XML</h4>
                            <p>
                                ATMS - Agan Integration create an xml file from the extracted data.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={ApiIcon} alt="" />
                                <span>4</span>
                            </div>
                            <h4 className="appie-title">Send The XML to ATMS</h4>
                            <p>Send the generated XML fiel to Agan ATMS api.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
