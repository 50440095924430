import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {Link} from "react-router-dom";
function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <a href="/">
                        Home
                    </a>
                </li>
                <li>
                    <HashLink smooth to='/#process' >How It Works</HashLink>
                </li>
                <li>
                    <HashLink smooth to="/#features">Features</HashLink>
                </li>
                <li>
                    <HashLink smooth to="/#testimonials">Testimonials</HashLink>
                </li>
                <li>
                    <HashLink smooth to="/#pricing">Pricing</HashLink>
                </li>
                <li>
                    <HashLink smooth to="/#faq">Faq</HashLink>
                </li>
                <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
